import * as React from 'react';
import { RouteType } from '../types/route.types';
import { Supplier } from '../pages/supplier/supplier';
import { PowerBI } from '../pages/powerbi/powerbi';
import { RuleEngine } from '../pages/ruleEngine/ruleEngine';
import { MaterialPlatform } from 'src/pages/materialPlatform/materialPlatform';

export const routes: RouteType[] = [
	{
		path: '/',
		element: <Supplier />,
	},
	{
		path: '/supplier',
		element: <Supplier />,
	},
	{
		path: '/material',
		element: <MaterialPlatform />,
	},
	{
		path: '/ruleengine',
		element: <RuleEngine />,
	},
	{
		path: '/powerbi',
		element: <PowerBI />,
	},
	{
		path: '/user',
		element: <></>,
	},
];
