import * as React from 'react';
import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';
import { colors } from '../../theme';
import { Table } from '../shared/table/table';
import { MaterialTableType } from '../../types/material.types';
import { ToolTip } from '../shared/tooltip/tooltip';
import { minDate } from '../../utils/common';

const color = colors();

type MaterialActionType = {
	onEditClickCallback: Function;
};
const MaterialActionItems = ({ onEditClickCallback }: MaterialActionType) => {
	const classes = useStyles();

	return (
		<div className={classes.iconButton} id="action-buttons">
			<ToolTip
				tooltipText="Edit"
				children={
					<IconButton id="edit_row" size="small" onClick={() => onEditClickCallback()}>
						<EditIcon fontSize="inherit" />
					</IconButton>
				}
			/>
		</div>
	);
};

export const MaterialTable = ({ data, onCellClickCallback, onEditClickCallback }: MaterialTableType) => {
	const MaterialColumns: GridColDef[] = [
		{
			field: 'materialId',
			headerName: 'Material ID',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 80,
			flex: 1,
		},
		{
			field: 'materialName',
			headerName: 'Material Name',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 350,
			flex: 1,
		},
		{
			field: 'platform',
			headerName: 'Platform',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 200,
			flex: 1,
			renderCell: (params) => (
				<>
					{params.row?.platform && (
						<>
							<img
								src={require(`../../images/${params.row?.platform?.tpName
									?.toLocaleLowerCase()
									.replaceAll(' ', '')}.png`)}
								alt={params.row?.platform?.tpName}
								width="24px"
								height="24px"
							/>
							{params.row?.platform?.tpName}
						</>
					)}
				</>
			),
		},
		{
			field: 'scopeStartDate',
			headerName: 'Scope Start Date',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 150,
			flex: 1,
			renderCell: (params) => {
				let dates: string[] = params.row?.platform?.position.map((p) => p.scopeStartDate);
				return minDate(dates);
			},
		},
		{
			field: 'position',
			headerName: 'Position',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 220,
			flex: 1,
			renderCell: (params) => params.row?.platform?.position.map((p) => p.positionName).join(', '),
		},
		{
			field: 'comments',
			headerName: 'Comments',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 220,
			flex: 1,
			renderCell: (params) => params.row?.platform?.comments,
		},
		{
			field: 'action',
			headerName: '',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 100,
			flex: 1,
			align: 'right',
			renderCell: () =>
				MaterialActionItems({
					onEditClickCallback: onEditClickCallback,
				}),
		},
	];

	return (
		<Table
			width="calc(100% - 90px)"
			height="calc(100% - 210px)"
			columns={MaterialColumns}
			data={data}
			pageSize={25}
			pageSizeOptions={[10, 25, 50, 100]}
			onCellClickCallback={onCellClickCallback}
		></Table>
	);
};

const useStyles = makeStyles(() => ({
	iconButton: {
		paddingRight: '5px',
		visibility: 'hidden',
		'& .MuiIconButton-root': {
			color: color.signal.info,
		},
	},
}));
