import React, { ReactElement, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Checkbox, IconButton, Switch, TableCell } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { colors, theme } from '../../theme';
import { CustomAlert } from '../../components/shared/alert/alert';
import { CustomDate } from '../../components/shared/date/date';
import { SingleSelectDropDown } from '../../components/shared/dropdown/singleSelect';
import { TextBox } from '../../components/shared/textbox/textbox';
import { MatRoutineModifyType, MatRoutineType } from '../../types/routine.types';
import { ItemType } from '../../types/common.types';
import { CustomButton } from '../shared/button/button';

export const testid = 'material-routine-edit';

const color = colors();
const themes = theme().typography;

export const EditPanel = ({
	materialId,
	materialName,
	materialType,
	row,
	verRoutinesList,
	onSaveClick,
	onDiscardClick,
}: MatRoutineModifyType): ReactElement => {
	const classes = useStyles();

	const [routine, setRoutine] = useState<string>('-1');
	const [documentList, setDocumentList] = useState<ItemType[]>([]);
	const [document, setDocument] = useState<string>('-1');
	const [hasComment, setHasComment] = useState<boolean>(false);
	const [comment, setComment] = useState<string>('');
	const [isSkippable, setIsSkippable] = useState<boolean>(false);
	const [isShellSkippable, setIsShellSkippable] = useState<boolean>(false);
	const [isLiningSkippable, setIsLiningSkippable] = useState<boolean>(false);
	const [isPaddingSkippable, setIsPaddingSkippable] = useState<boolean>(false);
	const [hasSkipCondition, setHasSkipCondition] = useState<boolean>(false);
	const [skippablePct, setSkippablePct] = useState<string>('0');
	const [activeDate, setActiveDate] = useState<string | null>();
	const [allowSave, setAllowSave] = useState<boolean>(false);
	const [isNA, setIsNA] = useState<boolean>(false);

	useEffect(() => {
		if (row.isEdit) {
			handleRoutineSelect((verRoutinesList.findIndex((r) => r.id === row.routineId) + 1).toString());
			setDocument(row.documentId.toString());
			setHasComment(row.comments ? true : false);
			setComment(row.comments);
			setIsSkippable(row.isShellSkippable || row.isLiningSkippable || row.isPaddingSkippable);
			setActiveDate(row.activeDate);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let temp = routine !== '-1' && verRoutinesList[parseInt(routine) - 1].routineName === 'NA';
		setIsNA(temp);
		setAllowSave(temp || (routine && document && activeDate) ? true : false);
	}, [routine, document, activeDate]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!isSkippable) {
			setIsShellSkippable(false);
			setIsLiningSkippable(false);
			setIsPaddingSkippable(false);
			setHasSkipCondition(false);
			setSkippablePct('0');
		}
		if (isSkippable && row.isEdit) {
			setIsShellSkippable(row.isShellSkippable);
			setIsLiningSkippable(row.isLiningSkippable);
			setIsPaddingSkippable(row.isPaddingSkippable);
			setHasSkipCondition(row.skippablePct > 0 ? true : false);
			setSkippablePct(row.skippablePct.toString());
		}
	}, [isSkippable]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleRoutineSelect = (value: string) => {
		setRoutine(value);
		setDocument('-1');
		setDocumentList(
			verRoutinesList[parseInt(value) - 1]?.documents.map((doc) => ({ key: doc.keyId, value: doc.name })) || []
		);
		setIsNA(routine !== '-1' && verRoutinesList[parseInt(routine) - 1].routineName === 'NA');
	};

	const clearAll = () => {
		setRoutine('-1');
		setDocumentList([]);
		setDocument('-1');
		setHasComment(false);
		setComment('');
		setIsSkippable(false);
		setIsShellSkippable(false);
		setIsPaddingSkippable(false);
		setHasSkipCondition(false);
		setSkippablePct('0');
		setActiveDate(undefined);
	};

	const onSaveClickCallback = () => {
		let saveData: MatRoutineType = {
			ruleId: row.ruleId || '-1',
			routineId: verRoutinesList[parseInt(routine) - 1].id || '',
			routineName: verRoutinesList[parseInt(routine) - 1].routineName,
			documentId: !isNA ? parseInt(document) : 0,
			documentName: !isNA
				? verRoutinesList[parseInt(routine) - 1].documents.filter((doc) => doc.keyId === parseInt(document))[0].name
				: '',
			isShellSkippable: !isNA && isShellSkippable,
			isLiningSkippable: !isNA && isLiningSkippable,
			isPaddingSkippable: !isNA && isPaddingSkippable,
			skippablePct: !isNA ? parseInt(skippablePct) : 0,
			activeDate: !isNA ? activeDate || '' : null,
			endDate: null,
			comments: !isNA ? comment : '',
		};
		clearAll();
		onSaveClick(materialId, saveData);
	};

	const onDiscardClickCallback = () => {
		clearAll();
		onDiscardClick(materialId);
	};

	return (
		<>
			<TableCell className={classes.modifyRow}>{materialId}</TableCell>
			<TableCell>
				<div>{materialName}</div>
				<div className={classes.secondaryText}>{materialType}</div>
			</TableCell>
			<TableCell
				sx={{
					textWrap: 'nowrap',
					maxWidth: '100px',
				}}
			>
				<div className={classes.divComp}>
					<SingleSelectDropDown
						labelText="Routine"
						label=""
						data={
							verRoutinesList &&
							verRoutinesList.map((list, i) => {
								return { key: i + 1, value: list.routineName };
							})
						}
						value={routine || ''}
						width={332}
						hasTooltip
						onSelectCallback={(e) => handleRoutineSelect(e)}
					/>
					{!isNA && (
						<SingleSelectDropDown
							labelText="Document"
							label=""
							disabled={!routine}
							data={documentList}
							value={document || ''}
							width={332}
							onSelectCallback={setDocument}
						/>
					)}
					{!isNA && !hasComment && (
						<div id="add-comment" className={classes.textButton} onClick={() => setHasComment(true)}>
							Add comment
						</div>
					)}
				</div>
				{!isNA && hasComment && (
					<div className={classes.divComp}>
						<TextBox
							id={`${testid}-doc-name`}
							label=""
							labelText="Comments"
							width={332}
							value={comment || ''}
							onChangeCallback={setComment}
						/>
						<IconButton
							id="delete-comment"
							className={classes.iconButton}
							onClick={() => {
								setComment('');
								setHasComment(false);
							}}
						>
							<DeleteOutlineIcon />
						</IconButton>
					</div>
				)}
				{!isNA && (
					<div className={classes.divComp}>
						<div>
							<div className={classes.label}>Skippable</div>
							<Switch
								checked={isSkippable}
								onClick={() => setIsSkippable(!isSkippable)}
								className={classes.switch}
								data-testid={`${testid}-switch`}
							/>
						</div>
						{isSkippable && (
							<div className={classes.skipPosition}>
								<div className={classes.label}>Skippable Positions</div>
								<div>
									<Checkbox
										checked={isShellSkippable}
										size="small"
										className={classes.checkbox}
										onChange={(e) => setIsShellSkippable(e.target.checked)}
									/>
									<span className={classes.skipGroup}>Shell</span>
									<Checkbox
										checked={isLiningSkippable}
										size="small"
										className={classes.checkbox}
										onChange={(e) => setIsLiningSkippable(e.target.checked)}
									/>
									<span className={classes.skipGroup}>Lining</span>
									<Checkbox
										checked={isPaddingSkippable}
										size="small"
										className={classes.checkbox}
										onChange={(e) => setIsPaddingSkippable(e.target.checked)}
									/>
									<span className={classes.skipGroup}>Padding</span>
								</div>
							</div>
						)}
						{isSkippable && !hasSkipCondition && (
							<div id="add-skip-condition" className={classes.textButton} onClick={() => setHasSkipCondition(true)}>
								Add condition
							</div>
						)}
					</div>
				)}
				{!isNA && isSkippable && hasSkipCondition && (
					<div className={classes.divComp}>
						<SingleSelectDropDown
							labelText="Skippable if the material composition is less than"
							label=""
							data={[
								{ key: 10, value: '10%' },
								{ key: 15, value: '15%' },
								{ key: 20, value: '20%' },
								{ key: 25, value: '25%' },
								{ key: 30, value: '30%' },
								{ key: 35, value: '35%' },
								{ key: 40, value: '40%' },
								{ key: 45, value: '45%' },
								{ key: 50, value: '50%' },
								{ key: 55, value: '55%' },
								{ key: 60, value: '60%' },
							]}
							value={skippablePct}
							width={332}
							onSelectCallback={setSkippablePct}
						/>
						<IconButton
							id="delete-skippct"
							className={classes.iconButton}
							onClick={() => {
								setSkippablePct('0');
								setHasSkipCondition(false);
							}}
						>
							<DeleteOutlineIcon />
						</IconButton>
					</div>
				)}
				{!isNA && (
					<div className={classes.divComp}>
						<CustomDate
							width={332}
							label=""
							labelText="Active date"
							value={row.activeDate || ''}
							format="DD MMM, YYYY"
							onChangeCallback={setActiveDate}
							disablePast
							disableToday
						/>
					</div>
				)}
				<div className={classes.alert}>
					<CustomAlert
						open={true}
						message="Once activated, the routine cannot be edited. Please double check your settings before applying."
						severity="info"
					/>
				</div>
				<div className={classes.actions}>
					{!allowSave && (
						<CustomButton
							id="save-disabled"
							disabled={true}
							text="Apply routine"
							data-testid={`${testid}-save-disabled`}
						/>
					)}
					{allowSave && (
						<CustomButton
							id="save"
							text="Apply routine"
							onClickCallback={onSaveClickCallback}
							data-testid={`${testid}-save`}
						/>
					)}
					<CustomButton
						id="discard"
						text="Discard"
						onClickCallback={onDiscardClickCallback}
						data-testid={`${testid}-discard`}
					/>
				</div>
			</TableCell>
			<TableCell />
			<TableCell />
			<TableCell />
		</>
	);
};

const useStyles = makeStyles(() => ({
	secondaryText: {
		...themes.metaText,
		color: color.neutral[8],
	},
	iconButton: {
		padding: '5px !important',
		'& svg': {
			width: '16px',
			height: '16px',
			color: color.primary[90],
		},
	},
	modifyRow: {
		borderLeft: `5px solid ${color.signal.info}`,
	},
	divComp: {
		display: 'flex',
		alignItems: 'center',
		'& #delete-comment, #delete-skippct': {
			marginTop: '15px',
		},
		paddingBottom: '10px',
	},
	label: {
		...themes.metaText,
		padding: '0px 8px',
		marginBottom: '-5px',
	},
	textButton: {
		cursor: 'pointer',
		color: color.signal.info,
		marginTop: '10px',
	},
	switch: {
		padding: '8px !important',
		'& .MuiSwitch-track': {
			borderRadius: '16px',
		},
		'& .Mui-checked+.MuiSwitch-track': {
			backgroundColor: `${color.signal.info} !important`,
			opacity: '1 !important',
		},
		'& .MuiSwitch-thumb': {
			color: 'white',
		},
	},
	skipPosition: {
		marginLeft: '20px',
		'& .MuiCheckbox-root.Mui-checked': {
			color: color.primary[90],
		},
	},
	skipGroup: {
		verticalAlign: 'middle',
		marginRight: '15px',
	},
	checkbox: {
		paddingRight: '3px !important',
	},
	alert: {
		marginLeft: '8px',
		width: '800px',
		'& .MuiAlert-action': {
			display: 'none',
		},
	},
	actions: {
		'& #discard': {
			backgroundColor: color.primary[5],
			color: color.text.dark,
			border: 'none',
		},
		'& #save-disabled': {
			backgroundColor: color.neutral[6],
			color: color.text.light,
			border: 'none',
		},
		'& #save': {
			backgroundColor: color.primary[100],
			color: color.text.light,
			border: 'none',
		},
	},
}));
