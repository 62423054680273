import React, { ReactElement, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { ItemType } from '../../types/common.types';
import { CustomTab } from '../../components/shared/tab/tab';
import { Header } from '../../components/shared/header/header';
import { MaterialRoutine } from './materialRoutine';
import { VerificationRoutine } from './verificationRoutine';

export const RuleEngine = (): ReactElement => {
	const classes = useStyles();
	const tabs: ItemType[] = [
		{
			key: 0,
			value: 'Verification Routine',
		},
		{
			key: 1,
			value: 'Material Routine',
		},
	];

	const [activeTab, setActiveTab] = useState<number>(0);

	return (
		<>
			<Header title="Rule Engine" />
			<div className={classes.topPadding}>
				<CustomTab data={tabs} value={activeTab} onChangeCallback={setActiveTab} />
			</div>
			{activeTab === 0 ? <VerificationRoutine /> : <MaterialRoutine />}
		</>
	);
};

const useStyles = makeStyles(() => ({
	topPadding: {
		paddingTop: '15px',
	},
}));
