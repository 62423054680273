import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { AxiosRequestConfig } from 'axios';
import { graphScopes, loginRequest, msalInstance } from '../config/msalConfig';

export async function authInterceptor(config: AxiosRequestConfig) {
	const account = msalInstance.getActiveAccount();
	const { url } = config;
	if (!account) {
		throw Error('No active account! Verify a user has been signed in.');
	}
	try {
		const response = await msalInstance.acquireTokenSilent({
			...(url?.startsWith('https://graph.microsoft.com') ? graphScopes : loginRequest),
			account,
		});
		config.headers = {
			...config.headers,
			Authorization: `Bearer ${response.accessToken}`,
		};

		if (!localStorage.getItem('roles')) {
			let roles = JSON.parse(atob(response.accessToken.split('.')[1]))?.roles;
			localStorage.setItem('roles', JSON.stringify(roles));
		}
	} catch (e) {
		if (e instanceof InteractionRequiredAuthError) {
			//   toast('Session invalid. Please click here to login again', {
			//     type: 'error',
			//     toastId: 'interaction_required',
			//     onClick: () => msalInstance.loginRedirect(loginRequest),
			//     autoClose: false,
			//   });
		}
		console.error(e);
	}
	return config;
}
